import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Image, Row, Space, Typography } from 'antd';
import { useTranslation } from "react-i18next";
import ThrivveTabLogo from "../../../../assets/imgs/favicon-32x32.png";
import "../onboarding.style.css";


const AppSmartBanner = () => {
    const [showBanner, setShowBanner] = useState<boolean>(true);
    const { t } = useTranslation();

    function onDownLoad() {
        var userAgent = navigator.userAgent;
        if (/windows phone/i.test(userAgent)) {
            return window.open("https://play.google.com/store/apps/details?id=com.app.thrivve.finance.thrivve");
        }
        if (/android/i.test(userAgent)) {
            return window.open("https://play.google.com/store/apps/details?id=com.app.thrivve.finance.thrivve");
        }
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return window.open("https://apps.apple.com/us/app/thrivve-app/id6478132223");
        }
    }
    const bannerHandler = useCallback(function showSmartBanner() {
        var { userAgent } = navigator;
        if (window.innerWidth <= 500) {
            if (/iPad|iPhone|iPod/.test(userAgent)) {
                const head = document.querySelector("head");
                const iosMetatBanner = document.createElement("meta");
                iosMetatBanner.name = "apple-itunes-app";
                iosMetatBanner.content = "app-id=6478132223, app-argument=https://apps.apple.com/us/app/thrivve-app/id6478132223";
                head?.appendChild(iosMetatBanner);
                setShowBanner(false);
            }
        }
    }, []);
    useEffect(() => {
        bannerHandler();
    }, [bannerHandler]);
    return (

        showBanner || !showBanner
            ? <Row
                justify={"space-between"} align={"middle"} id={"customBanner"}
                className={`smartBanner bg-hide`}
            >
                <Col>
                    <Space direction={"horizontal"}>
                        <Image src={ThrivveTabLogo} preview={false} className='smartBannerImage' />
                        <Typography.Text className='bannerText'>
                            {/* {t("Thrivve app")} */}
                            {t("Thrivve is better on the App")}
                        </Typography.Text>
                    </Space>
                </Col>
                <Col>
                    <Button type='primary' onClick={onDownLoad} className="smartBanner-downloadBtn">
                        {t("Download")}
                    </Button>
                </Col>
            </Row >
            : <></>
    );
};

export default AppSmartBanner;
